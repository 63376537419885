import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import '../assets/styles/base/standard-page.scss';
import '../assets/styles/pages/accident-management.scss';
import Layout from '../components/layout';
import SubNav from "../components/sub-nav";
import SEO from '../components/seo';
import HomeIcon1 from '../images/crash-management/home-icon1.png'
import HomeIcon2 from '../images/crash-management/home-icon2.png'
import HomeIcon3 from '../images/crash-management/home-icon3.png'
import HomeIcon4 from '../images/crash-management/home-icon4.png'
import Tyres4U from '../images/logos/Tyreright.png'
import SGFleetLogo from '../images/logos/sgfleet-logo.png'
import SmhLogo from '../images/logos/smh-logo.png'
import PPLogo from "../images/logos/PandPlogo.png";
import AutoNewsLogo from "../images/logos/Fleet-Auto-News-Logo.png";
import UberLogo from "../images/Uber-Carshare-Logo.inline.svg";
import AccidentSubNav from "../components/accident-sub-nav";

const AccidentManagement = () => {
  const data = useStaticQuery(graphql`
    query {
      general {
        country_code
      }
    }
  `);
  return (
    <Layout wrapperClass="standard-page" showSiteIndex={true} hideHeaderFooter={true}>
      <SEO title="DingGo | Accident Management" />
      <AccidentSubNav />
      <div className="hero-banner">
        <div className="container">
          <h1>Transforming vehicle accident management</h1>
        </div>
      </div>
      <div className="main-wrapper">
        <section className="py-7 bg-light">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-3 client-type">
                <img src={HomeIcon1} />
                <div className="client-type-heading">Privately Owned</div>
                <p>Why waste your precious time phoning panel-shops, negotiating for priority service, driving around
                  and waiting for repair quotes? Leave it to the experts - we deliver professional-standard accident
                  management to private motorists.</p>
              </div>
              <div className="col-3 client-type">
                <img src={HomeIcon2} />
                <div className="client-type-heading">SME Fleets</div>
                {data.general.country_code === 'nz' &&
                  <p>Small to medium enterprises are the life-blood of the New Zealand economy. Crash Management is an
                    independent kiwi owned and operated company and we understand the sector well.</p>
                }
                {data.general.country_code === 'au' &&
                  <p>Small to medium enterprises are the life blood of the Australian economy, DingGo is an Australian
                    owned and operated company and we understand the sector well.</p>
                }
              </div>
              <div className="col-3 client-type">
                <img src={HomeIcon3} />
                <div className="client-type-heading">Corporate & Government</div>
                {data.general.country_code === 'nz' &&
                  <p>Crash Management serves some of New Zealand's largest corporate and government fleets.</p>
                }
                {data.general.country_code === 'au' &&
                  <p>DingGo Crash management serves some of Australia's largest corporate and Government fleets.</p>
                }
              </div>
              <div className="col-3 client-type">
                <img src={HomeIcon4} />
                <div className="client-type-heading">Business Partners</div>
                {data.general.country_code === 'nz' &&
                  <p>Crash Management partners with a range of providers in the insurance and fleet services sectors to
                    deliver best in class accident management to clients.</p>
                }
                {data.general.country_code === 'au' &&
                  <p>DingGo Crash Management partners with a range of providers in the insurance and fleet services sectors to
                    deliver best in class accident management to clients.</p>
                }
              </div>
            </div>
          </div>
        </section>
        <section className="py-5">
          <div className="container">
            <div className="row justify-content-center mb-4">
              <div className="col-12 text-center">
                <h2 className="fleet-section-title">Transforming Vehicle crash management through custom built technology to simplify and automate a complex process.</h2>
              </div>
            </div>
            <div className="row justify-content-center align-items-stretch">
              <div className="col-4">
                <div className="orange-card">
                  <div className="orange-card-heading">Accident Management</div>
                  <div className="orange-card-text">
                    <p>From incident to repaired vehicle back on the road, our accident management
                      service handles it all. We work closely with insurers and our repairer network,
                      providing towing, hire cars, and detailed reporting for a seamless experience,
                      ensuring you're back on the road stress-free.</p>
                  </div>
                </div>
              </div>
              <div className="col-4">
                <div className="orange-card">
                  <div className="orange-card-heading">Fleet Risk Management</div>
                  <div className="orange-card-text">
                    <p>Accident management is key to
                      mitigating fleet risk by swiftly
                      addressing incidents and
                      minimizing downtime. Proactive
                      measures, including driver
                      training and real-time reporting,
                      reduce repair costs and enhance
                      overall fleet performance,
                      ensuring safety and efficiency.</p>
                  </div>
                </div>
              </div>
              <div className="col-4">
                <div className="orange-card">
                  <div className="orange-card-heading">Seamless Mobility</div>
                  <div className="orange-card-text">
                    <p>When your car is in for repairs, we ensure you stay on the move with one of our replacement
                      vehicle options. Our hassle-free process includes both free and paid replacement vehicles and
                      drop-off and pick-up services with certain providers, allowing you to focus on your day while
                      we take care of your transportation needs. Your satisfaction and mobility are our top priorities
                      throughout the repair journey.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="py-5">
          <div className="container">
            <div className="row justify-content-center mb-4">
              <div className="col-12 text-center">
                {data.general.country_code === 'nz' &&
                  <iframe className="youtube-iframe" width="900" height="500" src="https://www.youtube.com/embed/o2WhfrDvWsg"
                          title="YouTube video player" frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                          allowFullScreen></iframe>
                }
                {data.general.country_code === 'au' &&
                  <iframe className="youtube-iframe" width="900" height="500" src="https://www.youtube.com/embed/KkJjnOVPqkc"
                          title="YouTube video player" frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                          allowFullScreen></iframe>
                }
              </div>
            </div>
          </div>
        </section>
        <section className="py-5">
          <div className="container">
            <div className="row justify-content-center mb-4">
              <div className="col-12 text-center">
                <div className="fleet-section-header">
                  <div className="fleet-section-title text-center">Trusted by the most innovative brands</div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <div className="fleet-brand">
                  <div className="fleet-brand-logo">
                    <img src={SGFleetLogo} />
                  </div>
                  <div className="fleet-brand-quote text-left">
                    “DingGo provides a great customer experience and is truly unique in the market. It’s an innovative
                    approach that can add a lot of value to our fleet customers and save them lots of time and stress”<br />
                    <br />
                    <strong>SG Fleet CEO, Robbie Blau.</strong>
                  </div>
                </div>
              </div>
              <div className="col-6">
                <div className="fleet-brand">
                  <div className="fleet-brand-logo">
                    <UberLogo />
                  </div>
                  <div className="fleet-brand-quote text-left">
                    “With more than 3000 jobs completed through DingGo, we
                    have seen an increase in customer satisfaction of our repair
                    process and a reduction in complaints by up to 50%. Obtaining
                    three quotes enables our customers to have greater
                    transparency in a ‘fair market cost’ to repair the vehicle. The
                    DingGo team have been keen to understand our business so
                    as to best meet the needs of our customers. This has been a
                    god send!”<br /> <br />
                    <strong>Mathew Byrne, Head of Claims, Uber Carshare</strong>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="py-5">
          <div className="container">
            <div className="row justify-content-center mb-4">
              <div className="col-12 text-center">
                <div className="fleet-section-header">
                  <div className="fleet-section-title text-center">Read more about DingGo</div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-4">
                <a target="_blank"
                   href="https://www.smh.com.au/business/companies/wait-times-for-car-repairs-balloon-after-sydney-s-december-hail-storm-20190121-p50smp.html">
                  <img src={SmhLogo} />
                </a>
              </div>
              <div className="col-4">
                <a target="_blank" href="https://fleetautonews.com.au/sg-fleet-backs-car-repair-start-up-dinggo/">
                  <img src={AutoNewsLogo} />
                </a>
              </div>
              <div className="col-4">
                <a target="_blank"
                   href="http://www.paintandpanel.com.au/news/news/dinggo-is-driving-business-for-independents">
                  <img src={PPLogo} />
                </a>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default AccidentManagement;